<template>
  <div class="m-page cloud-exhibitor-detail">
    <div class="name-container">
      <div class="name">{{ detail.companyName || '' }}</div>
    </div>
    <div class="content-container">
      <div class="introduction-wrap">
        <el-skeleton :loading="loading" animated>
          <span>{{ detail.introduction }}</span>
        </el-skeleton>
      </div>
      <div class="image-wrap">
        <el-row :gutter="5">
          <el-col v-for="item in detail.imgArr" :key="item" :span="12">
            <el-image :src="item || ''" :preview-src-list="detail.imgArr" />
          </el-col>
        </el-row>
      </div>
      <div class="contact-wrap">
        <el-skeleton :loading="loading" animated :rows="2">
          <template>
            <div class="address">苗圃地址：{{ detail.address }}</div>
            <div class="address">联系方式：{{ detail.contactName }} {{ detail.contactPhone }}</div>
          </template>
          <template #template>
            <el-skeleton-item variant="text" />
          </template>
        </el-skeleton>
      </div>
    </div>
  </div>
</template>

<script>
import { getExhibitorDetail } from '../../../api'
export default {
  name: 'CloudExhibitorDetail',

  data() {
    return {
      // 详情数据
      detail: {},
      loading: true
    }
  },
  
  created() {
    const { id } = this.$route.query
    id && this.getDetailData(id)
  },

  methods: {
    /* 获取展商详情数据 */
    getDetailData(id) {
      this.loading = true
      return getExhibitorDetail({ id }).then(res => {
        const detail = res.data
        const { companyName = '', imageList = '' } = detail
        this.detail = {
          ...detail,
          imgArr: imageList ? imageList.split(';') : []
        }
        companyName && (document.title = `${companyName} - ${document.title}`)
      }).catch(error => {
        console.log('getDetailData', error)
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
@ratio: 37.5 / 54;
.cloud-exhibitor-detail {
	min-height: 100vh;
	padding: calc(36px * @ratio) calc(36px * @ratio);
  box-sizing: border-box;
	background: linear-gradient(180deg, #BFCAE6 0%, #7583CA 100%);
}

.name-container {
	position: relative;
	width: 70%;
	height: calc(88px * @ratio);
	line-height: calc(88px * @ratio);
	text-align: center;
	margin: 0 auto;
	background: linear-gradient(180deg, #A9B9FF 0%, #7583CA 100%);
	border-radius: calc(44px * @ratio);
	border: calc(4px * @ratio) solid #7E94D1;
}

.name-container .name {
	padding: 0 calc(14px * @ratio);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: calc(36px * @ratio);
	font-weight: 600;
	color: #fff;
}

.name-container::before {
	content: '';
	position: absolute;
	left: calc(60px * @ratio);
	bottom: calc(-48px * @ratio);
	display: block;
	width: calc(20px * @ratio);
	height: calc(60px * @ratio);
	background: linear-gradient(180deg, #BFCAE6 0%, #7583CA 50%, #BFCAE6 100%);
	border-radius: calc(44px * @ratio);
}
.name-container::after {
	content: '';
	position: absolute;
	right: calc(60px * @ratio);
	bottom: calc(-48px * @ratio);
	display: block;
	width: calc(20px * @ratio);
	height: calc(60px * @ratio);
	background: linear-gradient(180deg, #BFCAE6 0%, #7583CA 50%, #BFCAE6 100%);
	border-radius: calc(44px * @ratio);
}

.content-container {
	// min-height: calc(400px * @ratio);
	margin: calc(20px * @ratio) 0 0;
	padding: calc(32px * @ratio) calc(36px * @ratio);
	border: calc(4px * @ratio) solid #7E94D1;
	border-radius: calc(12px * @ratio);
	background: #FFFFFF;
	line-height: calc(42px * @ratio);
	font-size: calc(30px * @ratio);
	color: #33333F;
}

.content-container .introduction-wrap {
	margin-bottom: calc(28px * @ratio);
}

.content-container .image-wrap {
	display: flex;
	flex-wrap: wrap;
	gap: calc(10px * @ratio);
	margin-bottom: calc(28px * @ratio);
}

.content-container .image-wrap .el-image {
	width: calc(50vw - (36px + 4px) * @ratio * 2);
	max-width: calc(375px * @ratio);
	height: calc(50vw - (36px + 4px) * @ratio * 2);
	max-height: calc(375px * @ratio);
  object-fit: cover;
}
</style>